import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../config/axiosConfig";

const AUTH_URL = '/auth/';

const authenticatedUser = JSON.parse(localStorage.getItem("authenticatedUser"));

const modulePrefix = "auth";

const initialState = {
    isAuthenticated: false,
    unauthorized: false,
    accessToken: authenticatedUser?.accessToken ? authenticatedUser.accessToken : null,
    role: authenticatedUser?.role ? authenticatedUser.role : null,
    email: authenticatedUser?.email ? authenticatedUser.email : null,
    name: authenticatedUser?.name ? authenticatedUser.name : null,
    refreshToken: authenticatedUser?.refreshToken ? authenticatedUser.refreshToken : null,
    userID: authenticatedUser?.userID ? authenticatedUser.userID : null,
    branchID: authenticatedUser?.branchID ? authenticatedUser.branchID : null,
    companyID: authenticatedUser?.companyID ? authenticatedUser.companyID : null
}

export const refreshToken = createAsyncThunk(
    `${modulePrefix}/refreshToken`,
    async (_, thunkAPI) => {
        try {
            const response = await instance.get(`${AUTH_URL}refresh`);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setPersist: (state, action) => {
            state.persist = action.payload;
        },
        setCredentials: (state, action) => {
            const { emailAddress, firstName, lastName, token: accessToken, userType: role, refreshToken, branchID, companyID, id: userID } = action.payload;
            const name = firstName + ' ' + lastName;
            localStorage.setItem("authenticatedUser", JSON.stringify({ role, emailAddress, name, accessToken, refreshToken, branchID, companyID, userID }));
            state.role = role;
            state.email = emailAddress;
            state.name = name;
            state.branchID = branchID;
            state.companyID = companyID;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            state.isAuthenticated = true;
        },
        logout: (state, action) => {
            state.isAuthenticated = false;
            state.role = null;
            state.email = null;
            state.name = null;
            state.accessToken = null;
            localStorage.removeItem("authenticatedUser");
        },
        unauthorized: (state, action) => {
            state.unauthorized = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(refreshToken.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.accessToken = action.payload.accessToken;
                state.role = action.payload.role;
                state.email = action.payload.email;
                state.name = action.payload.name;
                localStorage.setItem("authenticatedUser", JSON.stringify(action.payload));
            })
            .addCase(refreshToken.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.isAuthenticated = false;
                state.accessToken = null;
                state.role = null;
                state.email = null;
                state.name = null;
                console.log("Refresh Token Rejected!")
            })
    }
});

export const {
    setPersist, setCredentials, logout
} = authSlice.actions;

export default authSlice.reducer;