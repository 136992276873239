import { Navigate } from 'react-router-dom';
import dashboardRoutes from 'app/pages/dashboard/DashboardRoutes';
import NotFound from 'app/pages/sessions/NotFound';
import sessionRoutes from 'app/pages/sessions/SessionRoutes';
import companyRoutes from 'app/pages/company/CompanyRoutes';
import AuthGuard from './auth/AuthGuard';
import SobaLayout from './components/SobaLayout/SobaLayout';
import branchRoutes from './pages/branch/BranchRoutes';
import staffRoutes from './pages/staff/StaffRoutes';
import vehicleTypeRoutes from './pages/vehicleType/VehicleTypeRoutes';
import driverRoutes from './pages/driver/DriverRoutes';
import vehicleRoutes from './pages/vehicle/VehicleRoutes';
import customerRoutes from './pages/customer/CustomerRoutes';
import customerRequestRoutes from './pages/customerRequest/CustomerRequestRoutes';
import parcelTypeRoutes from './pages/parcelType/ParcelTypeRoutes';
import parcelRoutes from './pages/parcel/ParcelRoutes';
import parcelMovementRoutes from './pages/parcelMovement/ParcelMovementRoutes';
import userRoutes from './pages/user/UserRoutes';
import templatesRoutes from './pages/templates/TemplatesRoutes';

const routes = [
	{
		element: (
			<AuthGuard>
				<SobaLayout />
			</AuthGuard>
		),
		children: [
			...dashboardRoutes,
			...companyRoutes,
			...branchRoutes,
			...staffRoutes,
			...vehicleTypeRoutes,
			...driverRoutes,
			...vehicleRoutes,
			...templatesRoutes,
			...customerRoutes,
			...customerRequestRoutes,
			...parcelTypeRoutes,
			...parcelRoutes,
			...parcelMovementRoutes,
			...userRoutes,
		],
	},
	...sessionRoutes,
	{ path: '/', element: <Navigate to="dashboard" /> },
	{ path: '*', element: <NotFound /> },
];

export default routes;
