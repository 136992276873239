const ROLES = {
    'SuperAdmin': "Admin",
    'Admin': "Admin",
    'Staff': "Admin"
}

export const authRoles = {
    sa: [ROLES.SuperAdmin], // Only Super Admin has access
    admin: [ROLES.SuperAdmin, ROLES.Admin],
    staff: [ROLES.SuperAdmin, ROLES.Admin, ROLES.Staff],
}
