import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const VehicleTypeList = Loadable(lazy(() => import('./VehicleTypeList')));
const VehicleTypeForm = Loadable(lazy(() => import('./VehicleTypeForm')));
const VehicleTypeDetails = Loadable(lazy(() => import('./VehicleTypeDetails')));

const vehicleTypeRoutes = [
	{ path: '/vehicle-type', element: <VehicleTypeList />, auth: authRoles.staff },
	{ path: '/vehicle-type/new', element: <VehicleTypeForm />, auth: authRoles.sa },
	{ path: '/vehicle-type/:id', element: <VehicleTypeDetails />, auth: authRoles.staff },
	{ path: '/vehicle-type/update/:id', element: <VehicleTypeForm />, auth: authRoles.sa },
];

export default vehicleTypeRoutes;