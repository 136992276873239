import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ParcelTypeList = Loadable(lazy(() => import('./ParcelTypeList')));
const ParcelTypeForm = Loadable(lazy(() => import('./ParcelTypeForm')));
const ParcelTypeDetails = Loadable(lazy(() => import('./ParcelTypeDetails')));

const parcelTypeRoutes = [
	{ path: '/parcel-type', element: <ParcelTypeList />, auth: authRoles.staff },
	{ path: '/parcel-type/new', element: <ParcelTypeForm />, auth: authRoles.sa },
	{ path: '/parcel-type/:id', element: <ParcelTypeDetails />, auth: authRoles.staff },
	{ path: '/parcel-type/update/:id', element: <ParcelTypeForm />, auth: authRoles.sa },
];

export default parcelTypeRoutes;